import React from 'react'
import ReactGA from "react-ga4"

const Unauthorized = () => {

    ReactGA.send({
        hitType: "pageview",
        page: "/unauthorized",
        title: "Unauthorized"
    })

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center vh-100 phox-font bg-dark">
            <div >
                <div className=' d-flex justify-content-center align-items-center'>
                    <img src="/img/logo.png" alt="logo img" style={{ height: '80px' }} className='mb-4' />
                </div>
                <div className='text-center'>
                    <h1 className='text-light fw-bolder' style={{ fontSize: '100px', fontWeight: '100px' }}>401</h1>
                    <h3 className='text-danger'>  <i className='fe fe-x-octagon'></i> Unauthorized Route  </h3>
                </div>
            </div>
        </div >
    )
}

export default Unauthorized

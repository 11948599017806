import { Authenticator, Heading, Image, View } from '@aws-amplify/ui-react';
import { FullStory } from '@fullstory/browser';
import { API, Auth } from 'aws-amplify';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Row, Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonHeader, getUserById } from '../../components/AuthLayout';
import Spinner from '../../components/Spinner';
import { PAGE_TITLE } from '../../helpers';
import { storeUser } from '../../stores/slice';
import Host from './../../data/host.json';
import '@aws-amplify/ui-react/styles.css';
import ReactGA from "react-ga4"

export const createUserMutation = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

export const updateUserMutation = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const createUserLocationMutation = /* GraphQL */ `
  mutation CreateUserLocation($input: CreateUserLocationInput!) {
    createUserLocation(input: $input) {
      id
    }
  }
`;
const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;

const Login = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/",
		})
		document.title = `Sign In ${PAGE_TITLE}`;
	}, [])

	console.log('host', window.location.host);

	let customProvider = '';
	if (Object.keys(Host).includes(window.location.host) && Host[window.location.host].customProvider) {
		customProvider = Host[window.location.host].customProvider;
	}



	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [image, setImage] = useState('')
	const [user, setUser] = useState();
	const [dbUser, setDbUser] = useState();
	const [spinner, showSpinner] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const components = {
		SignIn: { Header: () => <CommonHeader title='Sign In' subtitle='The Tech-Enabled Solution for Healthcare Logistics' setImage={setImage} /> },
		SignUp: { Header: () => <CommonHeader title='Sign Up' subtitle='Enter your details to continue' setImage={setImage} /> },
		ConfirmSignUp: { Header: () => <Heading textAlign='center' level={3}>Enter Information 1:</Heading> },
		SetupTOTP: { Header: () => <Heading textAlign='center' level={3}>Enter Information 2:</Heading> },
		ConfirmSignIn: { Header: () => <Heading textAlign='center' level={3}>Enter Information 3:</Heading> },
		ResetPassword: { Header: () => <CommonHeader title='Forgot Password' subtitle='Enter your email to continue' setImage={setImage} /> },
		ConfirmResetPassword: { Header: () => <CommonHeader title='Forgot Password' subtitle='Please use the verification code sent to your email to reset your password' setImage={setImage} /> },
	};

	const adLogin = () => {
		showSpinner(true);
		Auth.federatedSignIn({ customProvider: customProvider })
	}

	useEffect(() => {
		showSpinner(true);

		Auth.currentAuthenticatedUser().then(async (user) => {
			setUser(user);
		}).catch((error) => {
			console.error(error);
		}).finally(() => {
			showSpinner(false);
		});

	}, []);


	useEffect(() => {
		let loginProvider = 'Email';
		if (user?.username) {
			setIsModalOpen(false);
			if (user.signInUserSession.idToken.payload.identities) loginProvider = user.signInUserSession.idToken.payload.identities[0].providerName;

			API.graphql({ query: getUserById, variables: { id: user.signInUserSession.idToken.payload.sub } }).then((data) => {
				const dbUser = data?.data?.getUser;
				if (dbUser) {
					setDbUser(dbUser)
					if (!dbUser?.name) setIsModalOpen(true)
					if (dbUser.active === true && dbUser.role) {
						let name = 'User';
						if (user.attributes?.name) name = user.attributes.name;
						if (user.signInUserSession.idToken.payload.given_name) name = `${user.signInUserSession.idToken.payload.given_name} ${user.signInUserSession.idToken.payload.family_name}`;

						API.graphql({
							query: updateUserMutation,
							variables: {
								input: {
									id: user.signInUserSession.idToken.payload.sub,
									// name: name,
									loginProvider: loginProvider,
									loginAt: moment().unix()
								}
							}
						}).then(() => {
							dispatch(storeUser(dbUser))

							FullStory('setIdentity', {
								uid: dbUser?.hideSignUp,
								properties: {
									displayName: dbUser?.name,
									email: dbUser?.email,
									role: dbUser?.role
								}
							})

							navigate('/dashboard');
						}).catch(error => {
							console.error(error);
						})
					} else {
						toast('We are reviewing your account and will get back to you in 24-48 hours.')
					}
				} else {
					const createUser = API.graphql({
						query: createUserMutation,
						variables: {
							input: {
								id: user.signInUserSession.idToken.payload.sub,
								name: `${user.signInUserSession.idToken.payload.given_name || ''} ${user.signInUserSession.idToken.payload.family_name || ''}`,
								email: user.signInUserSession.idToken.payload.email,
								userLocationId: user.signInUserSession.idToken.payload.sub,
								loginProvider: loginProvider,
								active: false,
							}
						},
					});

					const createUserLocation = API.graphql({
						query: createUserLocationMutation,
						variables: {
							input: {
								id: user.signInUserSession.idToken.payload.sub
							}
						},
					});

					Promise.all([createUser, createUserLocation]).then(async (res) => {
						try {
							let responseId = res[0]?.data?.createUser?.id;
							const slackInput = {
								channel: 'signups',
								message: ` <https://${process.env.REACT_APP_ADMIN_PORTAL_URL}/user/${responseId}/overview|${user.signInUserSession.idToken.payload.given_name} ${user.signInUserSession.idToken.payload.family_name}> (${user.signInUserSession.idToken.payload.email}) has signed from HQ portal, please review.`,
								user: user.signInUserSession.idToken.payload.email
							}
							await API.graphql({ query: sendSlackAlert, variables: { input: slackInput } })
						} catch (error) {
							console.error('Error sending slack alert');
						}
						toast('We are reviewing your account and will get back to you in 24-48 hours.')
						setIsModalOpen(false);
					}).catch((error) => {
						console.error(error);
						setIsModalOpen(false);
					})
				}

			}).catch((error) => {
				console.error(error);
				setIsModalOpen(false);
			})
		}
	}, [user]);
	const updateUser = (e) => {
		const createUser = API.graphql({
			query: updateUserMutation,
			variables: {
				input: {
					id: user?.signInUserSession.idToken.payload.sub,
					name: `${e.first_name} ${e.last_name}`,
				},
			},
		});
		Promise.all([createUser])
			.then(() => {
				toast(
					"We are reviewing your account and will get back to you in 24-48 hours."
				);
				setIsModalOpen(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};
	return (
		<Row className='d-flex'>
			<Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
				<Modal.Header>
					<Modal.Title className="modal-title">Update User Name</Modal.Title>
				</Modal.Header>
				<form
					onSubmit={(e) => {
						e.preventDefault();

						// JavaScript validation for alphabetic characters only
						const firstName = e.target.first_name.value.trim();
						const lastName = e.target.last_name.value.trim();
						const alphaRegex = /^[A-Za-z]+$/;

						if (!alphaRegex.test(firstName)) {
							alert("First name should contain only alphabets.");
							return;
						}

						if (!alphaRegex.test(lastName)) {
							alert("Last name should contain only alphabets.");
							return;
						}

						const formData = {
							first_name: firstName,
							last_name: lastName,
						};
						updateUser(formData);
					}}
				>
					<Modal.Body>
						<Col lg={6} md={6}>
							<div className="form-group">
								<label>Email:</label>
								<label>{dbUser?.email}</label>
							</div>
						</Col>
						<Row>
							<Col lg={6} md={6}>
								<div className="form-group">
									<label htmlFor="first_name">First Name</label>
									<input
										defaultValue={dbUser?.name?.trim().split(/\s+/)[0]}
										type="text"
										className="form-control"
										id="first_name"
										name="first_name"
										placeholder="Please enter First Name"
										required
										pattern="[A-Za-z]+" // HTML5 pattern validation for alphabets only
										title="First name should contain only alphabets."
									/>
								</div>
							</Col>
							<Col lg={6} md={6}>
								<div className="form-group">
									<label htmlFor="last_name">Last Name</label>
									<input
										defaultValue={dbUser?.name?.trim().split(/\s+/)[1]}
										type="text"
										className="form-control"
										id="last_name"
										name="last_name"
										placeholder="Please enter Last Name"
										required
										pattern="[A-Za-z]+" // HTML5 pattern validation for alphabets only
										title="Last name should contain only alphabets."
									/>
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="display-flex-start">
						<Spinner display={spinner}>
							<button type="submit" className="btn btn-dark ms-2">
								Update
							</button>
							<button
								type="button"
								className="btn btn-link text-muted"
								onClick={() => setIsModalOpen(false)}
							>
								Cancel
							</button>
						</Spinner>
					</Modal.Footer>
				</form>
			</Modal>
			<Col lg={12} xl={6} className='card-container my-auto'>
				<Card className='login-card-container mx-auto my-auto py-5'>
					<View textAlign='center' padding='1.5rem 0rem'>
						<Image alt='Phox logo' src='/img/logo.png' className='w-50' />
					</View>

					<Spinner display={spinner}>
						{
							user?.signInUserSession?.idToken?.payload?.sub ? <div className='text-center'>
								<h1 className='text-center mb-0'>
									{`Hi ${user.signInUserSession.idToken.payload.name || user.signInUserSession.idToken.payload.given_name || user.signInUserSession.idToken.payload.email || "User"}`}
								</h1>
								<p>
									Please wait while we setup your session
								</p>
								<Spinner display={true} />
							</div> : <div>
								<h1 className='text-center'>Phox Health HQ</h1>

								{
									customProvider ?
										<div className='mx-4'>
											<button className='btn btn-dark w-100' onClick={() => adLogin()}>Sign In with {Host[window.location.host].customProviderName}</button>
										</div> : <Authenticator hideSignUp={true} components={components} loginMechanisms={['email']} socialProviders={[]} >
											{
												({ user }) => {
													if (user) setUser(user);
												}
											}
										</Authenticator>
								}
							</div>
						}
					</Spinner>
				</Card>
			</Col>
			<Col xl={6} className='my-auto mx-auto login-cover d-none d-xl-block bg-cover'>
				<img src='/img/login-cover-new.png' alt='Login-cover' className='login-cover-img img-fluid w-75' />
			</Col>
		</Row>
	)
}

export default Login;
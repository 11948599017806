import { StorageManager } from '@aws-amplify/ui-react-storage';
import { API, Storage } from 'aws-amplify';
import { uniqueId } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, FormLabel, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import ReactGA from "react-ga4"

const getList = `query ListTasks {
    listTasks(filter: {status: {eq: OPEN}}) {
        items {
        attachment
        comments
        createdTime
        description
        dueBy
        id
        label
        shipment {
            number
        }
        shipmentId
        status
        title
        user {
            name
            id
        }
        userId
        }
    }
    }`

const createTaskSub = `subscription createTaskSub {
        onCreateTask {
            attachment
            comments
            createdTime
            description
            dueBy
            id
            label
            shipmentId
            status
            title
            shipment {
            number
            }
            userId
            user {
            name
            id
            }
        }
            }`


const updateTaskSub = `subscription updateTaskSub {
                onUpdateTask {
                    attachment
                    comments
                    createdTime
                    description
                    dueBy
                    id
                    label
                    shipmentId
                    status
                    title
                    shipment {
                    number
                    }
                    userId
                    user {
                    name
                    id
                    }
                }
                    }`
const createTaskMutation = /* GraphQL */ `
            mutation CreateTask($input: CreateTaskInput!){
                createTask(input: $input) {
                id 
                attachment
                comments
                createdTime
                description
                dueBy
                id
                label
                shipment {
                    number
                }
                shipmentId
                status
                title
                user {
                    name
                    id
                }
                userId
                    }
            }`;
const updateTaskMutation = /* GraphQL */ `
            mutation UpdateTask($input: UpdateTaskInput!){
                updateTask(input: $input) {
                id
                attachment
                comments
                createdTime
                description
                dueBy
                id
                label
                shipment {
                    number
                }
                shipmentId
                status
                title
                user {
                    name
                    id
                }
                userId
                    }
            }`;

const shipmentIdByNumber = /* GraphQL */ `
            query MyQuery($number: Int!) {
            shipmentsByNumber(number: $number) {
                items {
                id
                }
            }}`;



export default function Task() {
    const [taskList, setTaskList] = useState({})
    const [rawTaskList, setRawTaskList] = useState([])
    const myUser = useSelector((state) => state.slice.USER)
    const [loading, setLoading] = useState(true)
    const [isFullScreen, setIsFullScreen] = useState(false);

    const params = useParams()

    async function getTaskList() {
        try {
            let res = await API.graphql({ query: getList })
            let data = res.data.listTasks.items
            setRawTaskList(data)
            const result = Object.groupBy(data, ({ label }) => label);
            setTaskList(result)
            setLoading(false)


            API.graphql({ query: createTaskSub }).subscribe({
                next: (response) => {
                    data.push(response.value.data.onCreateTask)
                    setRawTaskList(data)
                    const result = Object.groupBy(data, ({ label }) => label);
                    setTaskList(result)
                },
                error: (error) => console.error('onShipmentUpdate', error),
            });
            API.graphql({ query: updateTaskSub }).subscribe({
                next: (response) => {
                    let resData = response.value.data.onUpdateTask
                    if (resData.status !== 'DONE') {
                        let idx = data.findIndex(item => item.id === resData.id)
                        data[idx] = resData
                    } else data = data.filter(item => item.id !== resData.id)
                    setRawTaskList(data)
                    const result = Object.groupBy(data, ({ label }) => label);
                    setTaskList(result)
                },
                error: (error) => console.error('onShipmentUpdate', error),
            });
        } catch (error) {
            console.log(error)
        }
    }

	const handleFullPage = () => {
        const elem = document.documentElement;

        if (!document.fullscreenElement) {
            elem.requestFullscreen().then(() => setIsFullScreen(true)).catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen().then(() => setIsFullScreen(false));
        }
    }


    const searchUser = async (keyword) => {
        let items = [{ label: ' -- Add New Patient -- ', value: 'NEW' }];
        try {
            const apiName = 'api';
            const path = `/search/user?size=100&from=0`;
            let init = {
                body: {
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        }
                    ],
                    query: {
                        bool: {
                            must: [
                                { match: { active: true } },
                                {
                                    terms: { "role.keyword": ['OWNER', 'EDITOR', 'VIEWER'] }
                                }
                            ]
                        }
                    }
                }
            };



            if (keyword) {
                delete init.body.sort;
                init.body.query.bool.should = [
                    { "prefix": { "extId": keyword.toLowerCase() } },
                    { "prefix": { "firstName": keyword.toLowerCase() } },
                    { "prefix": { "name": keyword.toLowerCase() } },
                    { "prefix": { "lastName": keyword.toLowerCase() } },
                ]
            }

            let data = await API.post(apiName, path, init);
            data.hits.hits.forEach(item => items.push({
                label: item?._source?.name,
                value: item?._source?.id
            }));
            return items
        } catch (error) {
            console.error('error from  elastic search cutomer', error)
        }
    }

    async function getIdByShipmentNumber(number) {
        try {
            let res = await API.graphql({ query: shipmentIdByNumber, variables: { number } })
            let shipment = res.data.shipmentsByNumber.items[0]
            return shipment?.id
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        getTaskList()
    }, [])

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/task",
        })
    }, [])

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    function Card({ task, setShowModal, setNewTask }) {
        useEffect(() => {
            if (params?.id) {
                let task = rawTaskList.find(item => item.id === params?.id)
                setNewTask({ ...task, comments: JSON.parse(task.comments), username: task.user.name, userId: task.userId, shipmentNumber: task?.shipment?.number })
                let div = document.getElementById(params?.id)
                div.click()
            }
        }, [taskList])

        return (
            <div
                className='card mb-2 bg-light'
                id={task?.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    setNewTask({ ...task, comments: JSON.parse(task.comments), username: task.user.name, userId: task.userId, shipmentNumber: task?.shipment?.number })
                    setShowModal(true)
                }}
            >
                <div className="p-3">
                    {task?.shipment?.number && <h5 className='text-muted'>#{task?.shipment?.number}</h5>}
                    <h4>{task?.title}</h4>
                    <div className="row align-items-center">
                        <div className="col">
                            <p className={`card-text small text-body-secondary ${moment().unix() > task?.dueBy && 'text-danger'}`}>
                                <i className="fe fe-clock" />{" "}
                                {moment.unix(task?.dueBy).format("MMM-DD hh:mm A")}
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="avatar-group bg-white rounded-circle d-flex align-items-center justify-content-center p-3" style={{ width: '20px', height: '20px' }}>
                                <h5 className='m-0'>{task?.user?.name?.split(" ")[0][0].toUpperCase()}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function Column({ title, tasks }) {
        const [showModal, setShowModal] = useState(false)
        const [isLoading, setIsLoading] = useState(false)

        const [newTask, setNewTask] = useState({ createdTime: moment().unix(), description: "", dueBy: moment().unix(), label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })

        const [comment, setComment] = useState({ userId: myUser?.id, username: myUser?.name, message: '', createdAt: null })

        const processFile = ({ file }) => {
            const ext = file.name.split('.').pop();
            return { file, key: `${uniqueId()}.${ext}` };
        };

        const onUploadSuccess = ({ key }) => {
            Storage.get(key).then(url => {
                setNewTask((prev) => ({ ...prev, attachment: [...prev.attachment, url.split('?')[0]] }))
            });
        };

        async function handleTask(task) {
            try {
                let shipmentId = null;
                setIsLoading(true)
                if (task.shipmentNumber) {
                    shipmentId = await getIdByShipmentNumber(task.shipmentNumber)
                    if (!shipmentId) {
                        setIsLoading(false)
                        return toast.error("No Shipment Found. Please check the shipmnet number entered !")
                    }
                }

                let input = {
                    createdTime: moment().unix(), description: task.description, dueBy: task.dueBy, label: task.label, shipmentId, status: 'OPEN', title: task.title, userId: task.userId,
                    comments: JSON.stringify(task.comments), attachment: task.attachment
                }
                if (task?.id) {
                    delete input.createdTime
                    input.status = task.status
                    input.id = task.id
                }

                await API.graphql({ query: task?.id ? updateTaskMutation : createTaskMutation, variables: { input } })

                setShowModal(false)
                getTaskList()
                setNewTask({ createdTime: moment().unix(), description: "", dueBy: moment().unix(), label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })
                toast.success(`Task ${task?.id ? "updated" : 'added'} successfully!`)
            } catch (error) {
                console.log(error)
                setIsLoading(false)
                toast.error("Something went wrong !")
            }
        }

        return (
            <div className="bg-white rounded col col-3 p-0 border-">
                <div className={`p-3 rounded-top fw-bold`}>{title}</div>
                <div
                    style={{ overflow: "scroll", maxHeight: "70vh" }}
                    className='p-3'
                >
                    {tasks?.map((task, index) => (
                        <Card key={index} index={index} task={task} setShowModal={setShowModal} setNewTask={setNewTask} />
                    ))}
                </div>
                <Modal show={showModal} centered onHide={() => setShowModal(false)} size='xl'>
                    <Modal.Body>
                        <h1>{newTask?.id ? 'Update' : 'Create a new'}  task</h1>
                        <hr />
                        <form className='row justify-content-between' onSubmit={(e) => {
                            e.preventDefault()
                            handleTask(newTask)
                        }}>
                            <div className='d-flex gap-2 flex-column col col-8'>
                                <Form.Group>
                                    <FormLabel>Title</FormLabel>
                                    <Form.Control required type='text' placeholder='Add a title' defaultValue={newTask?.title} onChange={(e) => setNewTask((prev) => ({ ...prev, title: e.target.value }))} />
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>Description</FormLabel>
                                    <textarea required className='form-control' type='textarea' rows={3} cols={30} placeholder='Add a Description' defaultValue={newTask?.description} onChange={(e) => setNewTask((prev) => ({ ...prev, description: e.target.value }))} />
                                </Form.Group>
                                <div>
                                    <FormLabel><i className='fe fe-paperclip'></i> Attachments </FormLabel>
                                    <StorageManager
                                        acceptedFileTypes={['.png', '.jpeg', '.jpg']}
                                        accessLevel='public' maxFileCount={3}
                                        showImages={false}
                                        processFile={processFile} onUploadSuccess={onUploadSuccess}
                                    />
                                    {newTask?.id && (
                                        <div className='d-flex gap-2 mt-3'>
                                            {newTask?.attachment?.map((img) => (
                                                <div key={img} className="position-relative" style={{ width: '60px', height: '60px' }}>
                                                    <a href={img} target='blank'>
                                                        <img src={img} height={60} width={60} style={{ objectFit: "cover" }} />
                                                    </a>
                                                    <button type="button" className="btn-close position-absolute" aria-label="Close" style={{ top: 0, right: 0 }} onClick={() => {
                                                        setNewTask((prev) => ({ ...prev, attachment: prev.attachment.filter(item => item != img) }))
                                                    }}></button>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>
                                <div>
                                    <FormLabel>Comments</FormLabel>
                                    <div className='row'>
                                        <div className="input-group mb-2 mr-sm-2">
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder='Add a comment...' value={comment?.message} onChange={(e) => setComment((prev) => ({ ...prev, message: e.target.value }))} />
                                            <div className="input-group-postpend">
                                                <button type='button' disabled={comment.message === ''} className='btn btn-info ms-2' onClick={() => {
                                                    setComment({ userId: myUser?.id, username: myUser?.name, message: '', createdAt: null })
                                                    setNewTask((prev) => ({ ...prev, comments: [{ ...comment, createdAt: moment().unix() }, ...prev.comments] }))
                                                }}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* comments */}
                                    <div className='mt-3'>
                                        {newTask.comments.map((item) => (
                                            <div key={item?.createdAt} className="comment mb-3">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="avatar-group bg-light rounded-circle d-flex align-items-center justify-content-center p-3" style={{ width: '20px', height: '20px' }}>
                                                            <h5 className='m-0'>{item?.username?.split(" ")[0][0]?.toUpperCase()}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col ms-n2">
                                                        <div className="comment-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h5 className="comment-title">{item?.username}</h5>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <time className="comment-time">{moment.unix(item?.createdAt).format("MMM DD hh:mm A")}</time>
                                                                </div>
                                                            </div>{" "}
                                                            <p className="comment-text">
                                                                {item?.message}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='col col-4 border-left d-flex gap-2 flex-column' style={{ borderLeft: "2px solid  #e6e6e6" }}>
                                <Form.Group>
                                    <FormLabel>Status</FormLabel>
                                    <Form.Select disabled={!newTask?.id} className='phox-form-select' value={newTask?.status} onChange={(e) => setNewTask((prev) => ({ ...prev, status: e.target.value }))}>
                                        <option value='TODO'>TODO</option>
                                        <option value='DONE'>DONE</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>User</FormLabel>
                                    <AsyncSelect cacheOptions defaultOptions
                                        placeholder='Select User'
                                        defaultValue={newTask?.userId ? { value: newTask?.userId, label: newTask?.username } : null}
                                        loadOptions={searchUser}
                                        onChange={(e) => setNewTask((prev) => ({ ...prev, userId: e.value, username: e.label }))}
                                    />
                                </Form.Group>
                                <div>
                                    <FormLabel>Label</FormLabel>
                                    <input type="text" required placeholder='Add a Label' className='form-control'
                                        value={newTask?.label} onChange={(e) => setNewTask((prev) => ({ ...prev, label: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <FormLabel>Shipment</FormLabel>
                                    <input type="text" placeholder='Enter Shipment Number' className='form-control'
                                        value={newTask?.shipmentNumber} onChange={(e) => setNewTask((prev) => ({ ...prev, shipmentNumber: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <FormLabel>Due By</FormLabel>
                                    <div>
                                        <input type="datetime-local" className='form-control'
                                            value={moment.unix(newTask?.dueBy).format('YYYY-MM-DDTHH:mm')}
                                            onChange={(e) => setNewTask((prev) => ({ ...prev, dueBy: moment(e.target.value).unix() }))} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end mt-auto'>
                                    <button className='btn btn-light' type='button' onClick={() => setShowModal(false)}>Cancel</button>
                                    <button disabled={isLoading} className='btn btn-info ms-1' type='submit'>
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            newTask?.id ? "Update Task" : "+ Create Task"
                                        )}
                                    </button>

                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <div className="d-flex justify-content-center">
                    <button onClick={() => setShowModal(true)} className='m-2 btn btn-sm btn-info'>+ Add Task</button>
                </div>
            </div>
        )
    }
    return (
        <div style={{ overflow: 'auto' }}>
            <PageHeader name='Tasks' >
            <div className="d-flex align-items-center">
                    <div className="fullPage cursor-pointer" onClick={handleFullPage}>
                        {isFullScreen ? (
                           <i className='fe fe-minimize'/>
                        ) : (
                           <i className='fe fe-maximize'/>
                        )}
                    </div>
            </div>
            </PageHeader>
            <Spinner display={loading}>
                <div className='px-5 d-flex gap-3 align-items-start' >
                    {Object.keys(taskList).map((item, idx) => (
                        <Column key={idx} title={item} tasks={taskList[item]} id={idx} />
                    ))}
                    <Column title={''} tasks={[]} id={'new'} />
                </div>
            </Spinner>
        </div>
    )
}


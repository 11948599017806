import { Authenticator, Heading, View } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
	CommonHeader,
	createCarrierUser,
	createShipperGroupUser,
	createShipperUser,
	createUserLocationMutation,
	createUserMutation,
	getInviteUser,
	updateInviteUser,
} from "../../components/AuthLayout";
import Spinner from "../../components/Spinner";
import { PAGE_TITLE } from "../../helpers";
import ReactGA from "react-ga4"

const Invite = () => {
	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/invite/:id",
		})
		document.title = `Registration ${PAGE_TITLE}`;
	}, [])




	Auth.signOut();
	const { id } = useParams();
	const navigate = useNavigate();
	const [inviteUser, setInviteUser] = useState([]);
	const [spinner, showSpinner] = useState(false);
	const [image, setImage] = useState("/img/login-cover-new.png");
	const env = process.env.REACT_APP_ENV

	useEffect(() => {
		if (window.top !== window.self) {
			navigate('/unauthorized')
		}
	}, []);


	const redirectUser = (role) => {
		if (role.carrier?.length) {
			navigate('/trax/download')
		} else if (role.shipperGroup?.length) {
			window.location.href = `https://${process.env.REACT_APP_SHIPPER_PORTAL_URL}`
		} else if (role.shipper?.length) {
			window.location.href = `https://${process.env.REACT_APP_SHIPPER_PORTAL_URL}`
		} else if (role.admin?.length) {
			navigate('/');
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				showSpinner(true);
				const { data } = await API.graphql({
					query: getInviteUser,
					variables: { id },
				});

				let userInvite = data.getInviteUser
				userInvite.role = JSON.parse(userInvite.role);

				if (userInvite.active === true) {
					setInviteUser(userInvite);
				} else {
					redirectUser(userInvite.role)
				}

			} catch (error) {
				console.error('error', error);
				toast.error("Something went wrong, Please try again");
			} finally {
				showSpinner(false);
			}
		};
		fetchData();
	}, [id, navigate]);

	const formFields = {
		signUp: {
			name: { order: 1, isRequired: true },
			email: {
				label: "Email ",
				order: 2,
				value: inviteUser.email,
				readOnly: true,
			}, // We intentionally put space here , DON'T REMOVE
			password: { order: 4 },
			confirm_password: { order: 5 },
		},
	};

	if (env === 'dev') formFields.signUp.phone_number = { order: 3, dialCodeList: ['+1', '+91'], value: inviteUser.phone || null, readOnly: !!inviteUser.phone }

	const components = {
		SignIn: () => null,
		SignUp: {
			Header: () => (
				<CommonHeader title="Welcome" subtitle="Complete the registration to access your account" setImage={setImage} />
			),
		},
		ConfirmSignUp: {
			Header: () => (
				<CommonHeader title="Verify Account" subtitle="Enter your details to continue" />
			),
		},
		SetupTOTP: {
			Header: () => (
				<Heading textAlign="center" level={3}>Enter Information 2:</Heading>
			),
		},
		ConfirmSignIn: {
			Header: () => (
				<Heading textAlign="center" level={3}>
					Enter Information 3:
				</Heading>
			),
		},
		ResetPassword: {
			Header: () => (
				<CommonHeader
					title="Forget Password"
					subtitle="Enter your email to continue"
				/>
			),
		},
		ConfirmResetPassword: {
			Header: () => (
				<CommonHeader
					title="Forget Password"
					subtitle="Please use the verification code sent to your email to reset your password"
				/>
			),
		},
	};

	const updateInviteUserStatus = (inviteUserId, userId) =>
		API.graphql({
			query: updateInviteUser,
			variables: { input: { id: inviteUserId, active: false, inviteUserUserId: userId } },
		});

	const UserAuthenticator = ({ user }) => {
		useEffect(() => {
			const createUser = async (cognitoUser) => {
				try {
					const { attributes, username } = cognitoUser;
					const { name, email, phone_number: phone } = attributes;

					const newUser = {
						id: username,
						active: true,
						userLocationId: username,
						active: true,
						name: name || email,
						email,
						role: inviteUser.role?.admin,
						loginProvider: 'Email',
					};

					if (env === 'dev') newUser.phone = phone ? phone : null


					if (newUser?.id) {
						const { data } = await API.graphql({
							query: createUserMutation,
							variables: { input: newUser },
						});
						await API.graphql({
							query: createUserLocationMutation,
							variables: { input: { id: username } },
						});

						if (data.createUser.id) {
							const tasks = [];

							inviteUser.role?.shipperGroup?.map((user) => {
								const input = {
									shipperGroupId: user.id,
									userId: cognitoUser.username,
									role: user.role,
								};
								tasks.push(createShipperGroupUser(input));
							});

							inviteUser.role?.shipper?.map((user) => {
								const input = {
									shipperId: user.id,
									userId: cognitoUser.username,
									role: user.role,
								};
								tasks.push(createShipperUser(input));
							});

							inviteUser.role?.carrier?.map((user) => {
								const input = {
									carrierId: user.id,
									userId: cognitoUser.username,
									role: user.role,
								};
								tasks.push(createCarrierUser(input));
							});

							tasks.push(updateInviteUserStatus(inviteUser.id, cognitoUser.username));

							await Promise.all(tasks);
						}
					}

					toast.success(
						"Awesome! Your account has been activated, please Sign In to access Phox Health"
					);
					await Auth.signOut();

					redirectUser(inviteUser.role)
				} catch (error) {
					console.error('error', error);
					toast.error("Something went wrong, Please try again");
				}
			};
			if (user) createUser(user);
		}, [user]);

		return null;
	};

	return (
		<>
			<Spinner display={spinner}>
				<Row className="d-flex">
					<Col lg={12} xl={6} className="card-container my-auto">
						<Card className="login-card-container mx-auto my-auto">
							<View textAlign='center' padding='1.5rem 0rem'>
								<Image alt='Phox logo' src='/img/logo.png' className='w-50' />
							</View>
							{
								inviteUser.active === true && <Authenticator
									socialProviders={inviteUser?.role?.carrier ? ['apple', 'google'] : []} // if user is a trax user
									components={components}
									formFields={formFields}
									initialState="signUp"
									signUpAttributes={env === 'dev' ? [] : ["name"]}
								>
									{({ user }) => <UserAuthenticator user={user} />}
								</Authenticator>
							}
						</Card>
					</Col>
					<Col
						xl={6}
						className="my-auto mx-auto login-cover d-none d-xl-block bg-cover"
					>
						<img
							src={image}
							alt="Login-cover"
							className="login-cover-img img-fluid w-75"
						/>
					</Col>
				</Row>
			</Spinner>
		</>
	);
};

export default Invite;

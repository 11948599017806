import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import ReactGA from "react-ga4"
import { PAGE_TITLE } from '../../helpers'

const DownloadApp = () => {

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/trax/download",
        })
        document.title = "Download App " + PAGE_TITLE
    }, [])



    return (
        <div className='d-flex justify-content-center '>
            <Card className='login-card-container mx-auto my-auto p-4'>
                <Card.Header className=''>
                    <div className="d-flex justify-content-center ">
                        <img src="/img/logo.png" alt="phox-logo-img" style={{ height: '50px' }} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='d-flex justify-content-center'><h1>Download Trax App</h1></div>

                    <Row className="d-flex justify-content-center" >
                        <Col className='col-6 my-2 d-flex justify-content-center'>
                            <img src="/img/PlayStore.png" alt="playStore-img" style={{ height: '40px', cursor: 'pointer' }} onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.phoxhealth.trax'} />
                        </Col>
                        <Col className='col-6 my-2 d-flex justify-content-center'>
                            <img src="/img/AppStore.png" alt="playStore-img" style={{ height: '40px', cursor: 'pointer' }} onClick={() => window.location.href = 'https://apps.apple.com/us/app/trax-by-phox-health/id6451401065'} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}

export default DownloadApp
